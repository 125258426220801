/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-console */
import React, { useState, useContext, useEffect, createContext } from 'react';
import { GraphQLClient } from 'graphql-request';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { LocaleContext } from './LocaleContext';

export const CustomerContext = createContext();

const isBrowser = typeof window !== 'undefined';

const PRO_PROGRAM_CUSTOMER_TAG = 'pro program';

const defaultState = {
  isAuthenticated: false,
};

const setDefaultState = currency => {
  try {
    if (isBrowser) {
      const customerKey = currency === 'CAD' ? 'customer_cad' : 'customer';
      const expiresAtKey =
        currency === 'CAD'
          ? 'customer_access_token_expires_at_cad'
          : 'customer_access_token_expires_at';

      const customerObject = JSON.parse(
        window.localStorage.getItem(customerKey)
      );
      const customerAccessTokenExpiresAt =
        window.localStorage.getItem(expiresAtKey);

      // Check if token is expired, if one exists - then renew it and set it as initial state.
      if (
        customerObject &&
        customerAccessTokenExpiresAt > new Date().toISOString()
      ) {
        return customerObject;
      }
      return defaultState;
    }
  } catch (error) {
    console.error(error);
  }
  return defaultState;
};

function CustomerProvider({ children, shopifyConfig = null }) {
  const localeContext = useContext(LocaleContext);
  const currency = localeContext?.currency || 'USD';
  const [customer, setCustomer] = useState(setDefaultState(currency));
  const customerKey = currency === 'CAD' ? 'customer_cad' : 'customer';
  const accessTokenKey =
    currency === 'CAD' ? 'customer_access_token_cad' : 'customer_access_token';
  const expiresAtKey =
    currency === 'CAD'
      ? 'customer_access_token_expires_at_cad'
      : 'customer_access_token_expires_at';

  let shopifySetting = shopifyConfig;

  if (!shopifyConfig) {
    shopifySetting =
      currency === 'CAD'
        ? {
            GATSBY_CHECKOUT_BASE_URL: `https://${process.env.GATSBY_SHOPIFY_CAD_DOMAIN_NAME}`,
            GATSBY_SHOPIFY_API_VERSION: process.env.GATSBY_SHOPIFY_API_VERSION,
            GATSBY_SHOPIFY_CUSTOMER_STOREFRONT_TOKEN:
              process.env.GATSBY_SHOPIFY_CAD_ACCESS_TOKEN,
          }
        : {
            GATSBY_CHECKOUT_BASE_URL: `https://${process.env.GATSBY_SHOPIFY_DOMAIN_NAME}`,
            GATSBY_SHOPIFY_API_VERSION: process.env.GATSBY_SHOPIFY_API_VERSION,
            GATSBY_SHOPIFY_CUSTOMER_STOREFRONT_TOKEN:
              process.env.GATSBY_SHOPIFY_ACCESS_TOKEN,
          };
  }

  const shopifyClient = new GraphQLClient(
    `${shopifySetting.GATSBY_CHECKOUT_BASE_URL}/api/${shopifySetting.GATSBY_SHOPIFY_API_VERSION}/graphql.json`,
    {
      headers: {
        'X-Shopify-Storefront-Access-Token':
          shopifySetting.GATSBY_SHOPIFY_CUSTOMER_STOREFRONT_TOKEN,
      },
    }
  );

  const saveCustomer = customerObject => {
    setCustomer({
      ...customerObject,
    });

    if (isBrowser) {
      window.localStorage.setItem(
        customerKey,
        JSON.stringify({
          ...customerObject,
        })
      );
    }
  };

  const getCustomerObject = async () => {
    if (isBrowser) {
      const customerAccessToken = window.localStorage.getItem(accessTokenKey);

      const shopifyObject = await shopifyClient
        .request(
          `
          query CustomerQuery {
            customer(customerAccessToken: "${customerAccessToken}") {
              acceptsMarketing
              createdAt
              email
              defaultAddress {
                id
              }
              displayName
              email
              firstName
              id
              lastName
              phone
              updatedAt
              tags
            }
          }
        `
        )
        .then(response => {
          if (response?.customer?.email?.length > 0) {
            const authenticatedCustomer = {
              ...response.customer,
              isAuthenticated: true,
            };
            saveCustomer(authenticatedCustomer);
            window?.dataLayer?.push({
              customerEmail: authenticatedCustomer.email,
            });
            return true;
          }
          return false;
        })
        .catch(error => {
          setCustomer(null);
          console.error(error);
          return false;
        });
      return shopifyObject;
    }
    return false;
  };

  const renewCustomerAccessToken = async customerAccessToken => {
    await shopifyClient
      .request(
        `
        mutation customerAccessTokenRenew($customerAccessToken: String!) {
          customerAccessTokenRenew(customerAccessToken: $customerAccessToken) {
            customerAccessToken {
              accessToken
              expiresAt
            }
            userErrors {
              field
              message
            }
          }
        }
      `,
        {
          customerAccessToken,
        }
      )
      .then(async ({ customerAccessTokenCreateWithMultipass }) => {
        if (
          customerAccessTokenCreateWithMultipass?.customerUserErrors.length > 0
        ) {
          console.error(
            'Error(s):',
            customerAccessTokenCreateWithMultipass.customerUserErrors
          );
        }

        if (
          customerAccessTokenCreateWithMultipass?.customerAccessToken &&
          isBrowser
        ) {
          window.localStorage.setItem(
            accessTokenKey,
            customerAccessTokenCreateWithMultipass.customerAccessToken
              .accessToken
          );
          window.localStorage.setItem(
            expiresAtKey,
            customerAccessTokenCreateWithMultipass.customerAccessToken.expiresAt
          );
          await getCustomerObject();
        }
      });
  };

  const checkCustomerAccessToken = async () => {
    try {
      if (isBrowser) {
        const customerObject = JSON.parse(
          window.localStorage.getItem(customerKey)
        );
        const customerAccessToken = window.localStorage.getItem(accessTokenKey);
        const customerAccessTokenExpiresAt =
          window.localStorage.getItem(expiresAtKey);

        if (customerAccessToken) {
          // Check if customer_access_token_expires_at is valid
          if (
            customerAccessTokenExpiresAt &&
            customerAccessTokenExpiresAt > new Date().toISOString()
          ) {
            if (customerObject && customerObject.isAuthenticated) {
              saveCustomer(customerObject);
            } else {
              await getCustomerObject();
            }
          } else {
            await renewCustomerAccessToken(customerAccessToken);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getMagentoSubscriptions = async () => {
    if (isBrowser) {
      try {
        const token = window?.localStorage?.getItem('cognito_id_token');
        if (token) {
          const response = await fetch(
            process.env.GATSBY_GET_SUBSCRIPTIONS_ENDPOINT,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const body = await response.json();
          return body?.subscriptions || [];
        }
      } catch (err) {
        console.log(err);
      }
    }
    return [];
  };

  const refreshCognitoIdToken = async () => {
    if (isBrowser) {
      const { CognitoUserPool, CognitoUser, CognitoRefreshToken } =
        await import('amazon-cognito-identity-js')
          .then(module => module)
          .catch(error => console.log(error));

      const cognitoRefreshToken = window.localStorage.getItem(
        'cognito_refresh_token'
      );
      if (cognitoRefreshToken && customer?.email) {
        const poolData = new CognitoUserPool({
          UserPoolId: process.env.GATSBY_COGNITO_USER_POOL_ID,
          ClientId: process.env.GATSBY_COGNITO_CLIENT_ID,
        });

        const userData = {
          Username: customer.email,
          Pool: poolData,
        };
        const cognitoUser = new CognitoUser(userData);
        const token = new CognitoRefreshToken({
          RefreshToken: cognitoRefreshToken,
        });

        try {
          const session = await new Promise((resolve, reject) => {
            cognitoUser.refreshSession(token, (err, data) => {
              if (err) {
                reject(err);
              } else {
                resolve(data);
              }
            });
          });

          if (session?.accessToken?.jwtToken) {
            window.localStorage.setItem(
              'cognito_access_token',
              session.accessToken.jwtToken
            );
          }

          if (session?.idToken?.jwtToken) {
            window.localStorage.setItem(
              'cognito_id_token',
              session.idToken.jwtToken
            );
          }

          if (session?.idToken?.payload?.exp) {
            localStorage.setItem(
              'cognito_id_token_expiry',
              session.idToken.payload.exp
            );
          }

          if (session?.refreshToken?.jwtToken) {
            window.localStorage.setItem(
              'cognito_refresh_token',
              session.refreshToken.jwtToken
            );
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  const checkCognitoIdExpiry = async () => {
    if (isBrowser) {
      const cognitoIdTokenExpiry = window.localStorage.getItem(
        'cognito_id_token_expiry'
      );

      if (cognitoIdTokenExpiry) {
        const currentTime = Math.floor(Date.now() / 1000);
        const threshold = 10 * 60;

        if (parseInt(cognitoIdTokenExpiry, 10) - currentTime <= threshold) {
          await refreshCognitoIdToken();
        }
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (customer) await checkCustomerAccessToken();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer?.isAuthenticated]);

  return (
    <CustomerContext.Provider
      value={{
        customer,
        logoutCustomer: async () => {
          if (isBrowser) {
            const customerAccessToken =
              window.localStorage.removeItem(accessTokenKey);

            window.localStorage.removeItem(customerKey);
            window.localStorage.removeItem(accessTokenKey);

            // Remove cognito data
            window.localStorage.removeItem('cognito_id_token');
            window.localStorage.removeItem('cognito_access_token');
            window.localStorage.removeItem('cognito_refresh_token');
            window.localStorage.removeItem('cognito_id_token_expiry');

            // Reinitialize a new checkout on logout
            window.localStorage.removeItem('_checkoutId');
            window.localStorage.removeItem('checkoutActive');

            const logoutBaseUrl =
              currency === 'CAD'
                ? `https://${process.env.GATSBY_SHOPIFY_CAD_DOMAIN_NAME}`
                : process.env.GATSBY_CHECKOUT_BASE_URL;

            const logoutLink = `${logoutBaseUrl}/account/logout?return_url=/gatsby`;

            if (customerAccessToken) {
              await shopifyClient
                .request(
                  `
                  mutation customerAccessTokenDelete($customerAccessToken: String!) {
                    customerAccessTokenDelete(customerAccessToken: $customerAccessToken) {
                      deletedAccessToken
                      deletedCustomerAccessTokenId
                      userErrors {
                        field
                        message
                      }
                    }
                  }
                  `,
                  {
                    customerAccessToken,
                  }
                )
                .then(() => {
                  window.location = logoutLink;
                  // Shopify /gatsby endpoint is a redirect in Shopify to Gatsby Homepage
                });
            }
            window.location = logoutLink;
          }
        },
        getCustomerAccessToken: async multipassToken => {
          await shopifyClient
            .request(
              `
                mutation customerAccessTokenCreateWithMultipass(
                  $multipassToken: String!
                ) {
                  customerAccessTokenCreateWithMultipass(
                    multipassToken: $multipassToken
                  ) {
                    customerAccessToken {
                      accessToken
                      expiresAt
                    }
                    customerUserErrors {
                      code
                      field
                      message
                    }
                  }
                }
              `,
              {
                multipassToken,
              }
            )
            .then(async ({ customerAccessTokenCreateWithMultipass }) => {
              console.log(
                'customerAccessTokenCreateWithMultipass',
                customerAccessTokenCreateWithMultipass
              );

              if (
                customerAccessTokenCreateWithMultipass?.customerUserErrors
                  ?.length > 0
              ) {
                console.error(
                  'Error(s):',
                  customerAccessTokenCreateWithMultipass.customerUserErrors[0]
                );
              }

              if (
                customerAccessTokenCreateWithMultipass?.customerAccessToken &&
                isBrowser
              ) {
                window.localStorage.setItem(
                  accessTokenKey,
                  customerAccessTokenCreateWithMultipass.customerAccessToken
                    .accessToken
                );
                window.localStorage.setItem(
                  expiresAtKey,
                  customerAccessTokenCreateWithMultipass.customerAccessToken
                    .expiresAt
                );
                await getCustomerObject();
              }
            });
        },
        deleteAddress: async id => {
          await shopifyClient
            .request(
              `
              mutation customerAddressDelete($id: ID!, $customerAccessToken: String!) {
                customerAddressDelete(id: $id, customerAccessToken: $customerAccessToken) {
                  customerUserErrors {
                    code
                    field
                    message
                  }
                  deletedCustomerAddressId
                }
              }
              `,
              {
                customerAccessToken:
                  isBrowser && window.localStorage.getItem(accessTokenKey),
                id,
              }
            )
            .then(async ({ customerAddressDelete: { customerUserErrors } }) => {
              if (customerUserErrors?.length > 0) {
                console.error('Error(s):', customerUserErrors);
                return false;
              }
              return true;
            });
        },
        editAddress: async (id, addressObject) => {
          await shopifyClient
            .request(
              `
              mutation customerAddressUpdate($customerAccessToken: String!, $id: ID!, $address: MailingAddressInput!) {
                customerAddressUpdate(
                  customerAccessToken: $customerAccessToken
                  id: $id
                  address: $address
                ) {
                  customerAddress {
                    id
                  }
                  customerUserErrors {
                    code
                    field
                    message
                  }
                }
              }
              `,
              {
                customerAccessToken:
                  isBrowser && window.localStorage.getItem(accessTokenKey),
                id,
                address: addressObject,
              }
            )
            .then(async ({ customerUserErrors }) => {
              if (customerUserErrors?.length > 0) {
                console.error('Error(s):', customerUserErrors);
                return false;
              }
              return true;
            });
        },
        addAddress: async address => {
          await shopifyClient
            .request(
              `
              mutation customerAddressCreate($customerAccessToken: String!, $address: MailingAddressInput!) {
                customerAddressCreate(
                  customerAccessToken: $customerAccessToken
                  address: $address
                ) {
                  customerAddress {
                    id
                  }
                  customerUserErrors {
                    code
                    field
                    message
                  }
                }
              }
              `,
              {
                customerAccessToken:
                  isBrowser && window.localStorage.getItem(accessTokenKey),
                address,
              }
            )
            .then(async ({ customerAddressCreate }) => {
              const { customerUserErrors } = customerAddressCreate;
              if (customerUserErrors?.length > 0) {
                console.error('Error(s):', customerUserErrors);
                return false;
              }
              return true;
            });
        },
        updateCustomer: async customerObject => {
          await shopifyClient
            .request(
              `
            mutation customerUpdate($customerAccessToken: String!, $customer: CustomerUpdateInput!) {
              customerUpdate(customerAccessToken: $customerAccessToken, customer: $customer) {
                customer {
                  acceptsMarketing
                  createdAt
                  displayName
                  email
                  firstName
                  lastName
                  phone
                  updatedAt
                  id
                }
                customerAccessToken {
                  accessToken
                  expiresAt
                }
                customerUserErrors {
                  code
                  field
                  message
                }
              }
            }
            `,
              {
                customerAccessToken:
                  isBrowser && window.localStorage.getItem(accessTokenKey),
                customer: customerObject,
              }
            )
            .then(({ customerUpdate }) => {
              const { customerAccessToken, customerUserErrors } =
                customerUpdate;
              if (customerUserErrors?.length > 0) {
                if (isBrowser) {
                  navigate('/account/settings', {
                    state: {
                      customerUserErrors: [
                        {
                          error:
                            customerUserErrors[0]?.message ||
                            JSON.stringify(customerUserErrors),
                        },
                      ],
                    },
                  });
                  return;
                }
              }

              if (customerAccessToken && isBrowser) {
                window.localStorage.setItem(
                  accessTokenKey,
                  customerAccessToken.accessToken
                );
                window.localStorage.setItem(
                  expiresAtKey,
                  customerAccessToken.expiresAt
                );
              }

              if (customerUpdate.customer) {
                const authenticatedCustomer = {
                  ...customerUpdate.customer,
                  isAuthenticated: true,
                };

                saveCustomer(authenticatedCustomer);
                if (isBrowser) {
                  navigate('/account/settings', {
                    state: {
                      customerUserErrors: [],
                    },
                  });
                }
              }
            });
        },
        updateCustomerDefaultAddress: async addressId => {
          await shopifyClient
            .request(
              `
              mutation customerDefaultAddressUpdate($customerAccessToken: String!, $addressId: ID!) {
                customerDefaultAddressUpdate(
                  customerAccessToken: $customerAccessToken
                  addressId: $addressId
                ) {
                  customer {
                    id
                  }
                  customerUserErrors {
                    code
                    field
                    message
                  }
                }
              }
            `,
              {
                customerAccessToken:
                  isBrowser && window.localStorage.getItem(accessTokenKey),
                addressId,
              }
            )
            .then(async ({ customerDefaultAddressUpdate }) => {
              const { customerUserErrors } = customerDefaultAddressUpdate;
              if (customerUserErrors?.length > 0) {
                console.error('Error(s):', customerUserErrors);
              }
              return customerDefaultAddressUpdate?.customer;
            });
        },
        fetchCustomerAddresses: async () => {
          const GET_ADDRESSES = `
            query CustomerAddressQuery($customerAccessToken: String!) {
              customerAddresses: customer(customerAccessToken: $customerAccessToken) {
                defaultAddress {
                  id
                }
                addresses(first: 250) {
                  edges {
                    node {
                      id
                      address1
                      address2
                      city
                      company
                      lastName
                      firstName
                      country_code: countryCodeV2
                      name
                      zip
                      province
                      phone
                    }
                  }
                }
              }
            }
          `;

          if (isBrowser) {
            const data = await shopifyClient.request(GET_ADDRESSES, {
              customerAccessToken: window.localStorage.getItem(accessTokenKey),
            });

            return data;
          }
          return null;
        },
        isAuthenticated: () => {
          if (!customer?.isAuthenticated && isBrowser) {
            // Check if the current page is related to account videos or currency is CAD
            const isAccountVideosPage =
              window.location.pathname.includes('/account/videos');
            const isCurrencyCAD = currency === 'CAD';

            if (isAccountVideosPage || isCurrencyCAD) {
              const redirectUrl = isAccountVideosPage
                ? encodeURIComponent(window.location.pathname)
                : '';

              // Construct the login URL based on the presence of a redirect URL and currency
              const baseLoginPath = isCurrencyCAD
                ? '/en-ca/account/login'
                : '/account/login';
              const loginUrl = redirectUrl
                ? `${baseLoginPath}?redirect_to=${redirectUrl}`
                : baseLoginPath;

              // Navigate to the login URL
              navigate(loginUrl);
            }
            navigate('/account/login');
          }
          return true;
        },
        isProStaff: () =>
          customer?.isAuthenticated &&
          customer?.tags?.length > 0 &&
          customer.tags.find(tag => tag === PRO_PROGRAM_CUSTOMER_TAG),
        saveCustomer,
        shopifyClient,
        getMagentoSubscriptions,
        refreshCognitoIdToken,
        checkCognitoIdExpiry,
        renewCustomerAccessToken,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
}

CustomerProvider.propTypes = {
  children: PropTypes.node.isRequired,
  shopifyConfig: PropTypes.shape({
    GATSBY_CHECKOUT_BASE_URL: PropTypes.string,
    GATSBY_SHOPIFY_API_VERSION: PropTypes.string,
    GATSBY_SHOPIFY_CUSTOMER_STOREFRONT_TOKEN: PropTypes.string,
  }),
};

export default CustomerProvider;
