const isBrowser = typeof window !== 'undefined';

const variantToGaItem = ({ quantity = 1, variant, product }) => ({
  item_id: variant?.sku,
  item_name: product?.title,
  item_category: product?.productType,
  item_brand: product?.vendor,
  item_variant: variant?.title?.replace('Default Title', ''),
  price: variant?.price?.amount ?? variant?.price,
  quantity,
  affiliation: 'Gatsby DTC Store',
});

const pushToDataLayer = (event, { items, ecommerce }) => {
  if (isBrowser) {
    // GA4 add_to_cart https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm
    window?.dataLayer?.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window?.dataLayer?.push({
      event,
      ecommerce: {
        ...ecommerce,
        items: items.map(item => variantToGaItem(item)),
      },
    });
  }
};

export default pushToDataLayer;
